// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader {
    border: 5px solid #f3f3f3;
    border-top: 5px solid #3442df;
    width: 30px;
    border-radius: 50%;
    height: 30px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}`, "",{"version":3,"sources":["webpack://./src/components/spinner/style.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,6BAA6B;IAC7B,WAAW;IACX,kBAAkB;IAClB,YAAY;IACZ,kCAAkC;AACtC;;AAEA;IACI;QACI,uBAAuB;IAC3B;;IAEA;QACI,yBAAyB;IAC7B;AACJ","sourcesContent":[".loader {\n    border: 5px solid #f3f3f3;\n    border-top: 5px solid #3442df;\n    width: 30px;\n    border-radius: 50%;\n    height: 30px;\n    animation: spin 1s linear infinite;\n}\n\n@keyframes spin {\n    0% {\n        transform: rotate(0deg);\n    }\n\n    100% {\n        transform: rotate(360deg);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
