import { Icon } from "@chakra-ui/react";
import { HiUsers } from "react-icons/hi";
import {
  MdContacts,
  MdHome,
  MdInsertChartOutlined,
  MdLeaderboard,
  MdLock,
} from "react-icons/md";
// icon
import React from "react";
import { AiFillFolderOpen, AiOutlineMail } from "react-icons/ai";
import { FaCalendarAlt, FaRupeeSign, FaTasks, FaWpforms } from "react-icons/fa";
import { LuBuilding2 } from "react-icons/lu";
import { FaCreativeCommonsBy } from "react-icons/fa";
import { ROLE_PATH } from "./roles";
import ChangeImage from "views/admin/image";
import Validation from "views/admin/validation";
import CustomField from "views/admin/customField";
import TableField from "views/admin/tableField";
import { TbExchange, TbTableColumn } from "react-icons/tb";
import { GrValidate } from "react-icons/gr";
import { VscFileSubmodule } from "react-icons/vsc";
import { IoDocumentTextOutline } from "react-icons/io5";
import { LiaFileContractSolid } from "react-icons/lia";
import { FaBuildingUser } from "react-icons/fa6";

// Admin Imports
const MainDashboard = React.lazy(() => import("views/admin/default"));

// My component
const Contact = React.lazy(() => import("views/admin/contact"));
const ContactView = React.lazy(() => import("views/admin/contact/View"));
const ContactImport = React.lazy(() =>
  import("views/admin/contact/components/ContactImport")
);

const User = React.lazy(() => import("views/admin/users"));
const UserView = React.lazy(() => import("views/admin/users/View"));

const Property = React.lazy(() => import("views/admin/property"));
const PropertyView = React.lazy(() => import("views/admin/property/View"));
const PropertyImport = React.lazy(() =>
  import("views/admin/property/components/PropertyImport")
);

const Lead = React.lazy(() => import("views/admin/lead"));
const LeadView = React.lazy(() => import("views/admin/lead/View"));
const LeadImport = React.lazy(() =>
  import("views/admin/lead/components/LeadImport")
);

// const Communication = React.lazy(() => import("views/admin/communication"));

const Task = React.lazy(() => import("views/admin/task"));
const TaskView = React.lazy(() =>
  import("views/admin/task/components/taskView")
);
const Calender = React.lazy(() => import("views/admin/calender"));
const Payments = React.lazy(() => import("views/admin/payments"));
const Role = React.lazy(() => import("views/admin/role"));

// const Document = React.lazy(() => import("views/admin/document"));

const EmailHistory = React.lazy(() => import("views/admin/emailHistory"));
const EmailHistoryView = React.lazy(() =>
  import("views/admin/emailHistory/View")
);

const Meeting = React.lazy(() => import("views/admin/meeting"));
const MettingView = React.lazy(() => import("views/admin/meeting/View"));

const PhoneCall = React.lazy(() => import("views/admin/phoneCall"));
const PhoneCallView = React.lazy(() => import("views/admin/phoneCall/View"));

const Report = React.lazy(() => import("views/admin/reports"));

const Listing = React.lazy(() => import("views/admin/listing"));
const DraftLease = React.lazy(() => import("views/admin/draftlease"));
const DraftLeaseView = React.lazy(() => import("views/admin/draftlease/View"));

const Tenants = React.lazy(() => import("views/admin/tenants"));
const TenantsView = React.lazy(() => import("views/admin/tenants/View"));

const DocSign = React.lazy(() => import("views/admin/docsign"));

// Auth Imports
const SignInCentered = React.lazy(() => import("views/auth/signIn"));
// admin setting
const AdminSetting = React.lazy(() => import("views/admin/adminSetting"));
const validation = React.lazy(() => import("views/admin/validation"));
const module = React.lazy(() => import("views/admin/moduleName"));

const routes = [
  // ========================== Dashboard ==========================
  {
    name: "Dashboard",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    path: "/default",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: MainDashboard,
  },
  // ========================== Admin Layout ==========================

  // --------------- Rental Routes --------------------

  {
    name: "Rentals",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    path: "/properties",
    icon: (
      <Icon
        as={LiaFileContractSolid}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    component: Property,
    children: [
      {
        name: "Properties",
        layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
        path: "/properties",
        icon: (
          <Icon as={LuBuilding2} width="20px" height="20px" color="inherit" />
        ),
        component: Property,
      },
      {
        name: "Tenants",
        layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
        path: "/tenants",
        icon: (
          <Icon
            as={FaBuildingUser}
            width="20px"
            height="20px"
            color="inherit"
          />
        ),
        component: Tenants,
      },
      {
        name: "Payments",
        layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
        path: "/payments",
        icon: (
          <Icon as={FaRupeeSign} width="20px" height="20px" color="inherit" />
        ),
        component: Payments,
      },
      {
        name: "Reporting",
        layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
        path: "/reporting-analytics",
        icon: (
          <Icon
            as={MdInsertChartOutlined}
            width="20px"
            height="20px"
            color="inherit"
          />
        ),
        component: Report,
      },
    ],
  },

  // ------------- Tenants Routes ------------------------

  {
    name: "Tenants",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    path: "/tenants",
    icon: (
      <Icon as={MdLeaderboard} width="20px" height="20px" color="inherit" />
    ),
    component: Tenants,
    under: "Rentals",
  },
  {
    name: "Tenants",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    under: "Tenants",
    parentName: "Tenants",
    path: "/tenants/:id",
    component: TenantsView,
  },

  // // ------------- Tenants Routes ------------------------

  // ------------- lead Routes ------------------------
  {
    name: "Leads",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    path: "/lead",
    icon: (
      <Icon as={MdLeaderboard} width="20px" height="20px" color="inherit" />
    ),
    component: Lead,
  },
  {
    name: "Leads",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    under: "lead",
    parentName: "Leads",
    path: "/leadView/:id",
    component: LeadView,
  },
  {
    name: "Lead Import",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    under: "lead",
    parentName: "Leads",
    path: "/leadImport",
    component: LeadImport,
  },

  // ------------- Task Routes ------------------------
  {
    name: "Tasks",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    path: "/task",
    icon: <Icon as={FaTasks} width="20px" height="20px" color="inherit" />,
    component: Task,
  },
  {
    name: "Tasks",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    under: "task",
    parentName: "Tasks",
    path: "/view/:id",
    component: TaskView,
  },
  // ------------- Calender Routes ------------------------
  {
    name: "Calender",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    path: "/calender",
    icon: (
      <Icon as={FaCalendarAlt} width="20px" height="20px" color="inherit" />
    ),
    component: Calender,
  },

  // -----------------------------Leasing Routes-------------------------------------
  {
    name: "Leases",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    icon: (
      <Icon
        as={IoDocumentTextOutline}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    path: "/draft-lease",
    component: DraftLease,
    children: [
      {
        name: "Listing",
        layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
        icon: (
          <Icon
            as={IoDocumentTextOutline}
            width="20px"
            height="20px"
            color="inherit"
          />
        ),
        path: "/listing",
        component: Listing,
        under: "Leases",
      },
    ],
  },

  {
    name: "Draft-Lease",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    under: "Leases",
    parentName: "Leases",
    path: "/draft-lease/:id",
    component: DraftLeaseView,
  },

  {
    name: "Listing",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    icon: (
      <Icon
        as={IoDocumentTextOutline}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    path: "/listing",
    component: Listing,
    under: "Leases",
  },

  // --------------- Communication Routes --------------------

  {
    name: "Communications",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    path: "/communication",
    icon: (
      <Icon as={MdLeaderboard} width="20px" height="20px" color="inherit" />
    ),
    component: EmailHistory,
    children: [
      {
        // separator: 'History',
        name: "Emails",
        layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
        path: "/email",
        icon: (
          <Icon as={AiOutlineMail} width="20px" height="20px" color="inherit" />
        ),
        component: EmailHistory,
      },
    ],
  },

  // --------------- contact Routes --------------------
  {
    name: "Contacts",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    path: "/contacts",
    icon: <Icon as={MdContacts} width="20px" height="20px" color="inherit" />,
    component: Contact,
  },
  {
    name: "Contacts",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    under: "contacts",
    parentName: "Contacts",
    path: "/contactView/:id",
    component: ContactView,
  },
  {
    name: "Contact Import",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    both: true,
    under: "contacts",
    parentName: "Contacts",
    path: "/contactImport",
    component: ContactImport,
  },
  // ------------- Property Routes ------------------------
  {
    name: "Properties",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    path: "/properties",
    icon: <Icon as={LuBuilding2} width="20px" height="20px" color="inherit" />,
    component: Property,
    under: "Rentals",
  },
  {
    name: "Properties ",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    parentName: "Properties",
    under: "properties",
    path: "/propertyView/:id",
    component: PropertyView,
  },

  {
    name: "Property Import",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    both: true,
    under: "properties",
    parentName: "Properties",
    path: "/propertyImport",
    component: PropertyImport,
  },
  // -----------------------------Admin setting-------------------------------------
  {
    name: "Admin Setting",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    parentName: "admin",
    under: "admin",
    path: "/admin-setting",
    component: AdminSetting,
  },
  // ------------- Communication Integration Routes ------------------------
  // {
  //   name: "Communication Integration",
  //   layout: [ROLE_PATH.superAdmin, ROLE_PATH.superAdmin, ROLE_PATH.user],
  //   path: "/communication-integration",
  //   icon: <Icon as={GiSatelliteCommunication} width='20px' height='20px' color='inherit' />,
  //   component: Communication,
  // },

  // ------------- Meeting Routes ------------------------
  // {
  //   name: "Meetings",
  //   layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
  //   path: "/metting",
  //   icon: <Icon as={SiGooglemeet} width="20px" height="20px" color="inherit" />,
  //   component: Meeting,
  // },
  // {
  //   name: "Meetings ",
  //   layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
  //   under: "Meetings",
  //   parentName: "Meetings",
  //   path: "/metting/:id",
  //   component: MettingView,
  // },
  // ------------- Phone Routes ------------------------
  // {
  //   name: "Calls",
  //   layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
  //   path: "/phone-call",
  //   icon: (
  //     <Icon as={PiPhoneCallBold} width="20px" height="20px" color="inherit" />
  //   ),
  //   component: PhoneCall,
  // },
  // {
  //   name: "Calls",
  //   layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
  //   under: "phone-call",
  //   parentName: "Calls",
  //   path: "/phone-call/:id",
  //   component: PhoneCallView,
  // },
  // ------------- Email Routes------------------------
  {
    // separator: 'History',
    name: "Emails",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    path: "/email",
    icon: (
      <Icon as={AiOutlineMail} width="20px" height="20px" color="inherit" />
    ),
    component: EmailHistory,
    under: "Communications",
  },
  {
    name: "Emails ",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    under: "Emails",
    parentName: "Emails",
    path: "/Email/:id",
    component: EmailHistoryView,
  },

  // ------------- Payments Routes ------------------------
  {
    name: "Payments",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    path: "/payments",
    icon: <Icon as={FaRupeeSign} width="20px" height="20px" color="inherit" />,
    component: Payments,
    under: "Rentals",
  },

  // ------------- Roles Routes ------------------------
  {
    name: "Roles",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    path: "/role",
    under: "role",
    icon: (
      <Icon
        as={FaCreativeCommonsBy}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    component: Role,
  },
  {
    name: "Custom Fields",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    path: "/custom-Fields",
    under: "customField",
    icon: <Icon as={FaWpforms} width="20px" height="20px" color="inherit" />,
    component: CustomField,
  },
  {
    name: "Change Images",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    path: "/change-images",
    under: "image",
    icon: <Icon as={TbExchange} width="20px" height="20px" color="inherit" />,
    component: ChangeImage,
  },
  {
    name: "Validation",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    path: "/validations",
    under: "Validation",
    icon: <Icon as={GrValidate} width="20px" height="20px" color="inherit" />,
    component: Validation,
  },
  {
    name: "Table Fields",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    path: "/table-field",
    under: "tableField",
    icon: (
      <Icon as={TbTableColumn} width="20px" height="20px" color="inherit" />
    ),
    component: TableField,
  },
  {
    name: "Module",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    path: "/module",
    under: "module",
    icon: (
      <Icon as={VscFileSubmodule} width="20px" height="20px" color="inherit" />
    ),
    component: module,
  },
  // // ------------- Text message Routes ------------------------
  // {
  //   name: "Text Msg",
  //   layout: [ROLE_PATH.admin, ROLE_PATH.user],
  //
  //   path: "/text-msg",
  //   icon: <Icon as={MdOutlineMessage} width='20px' height='20px' color='inherit' />,
  //   component: TextMsg,
  // },
  // {
  //   name: "Text Msg View",
  //   layout: [ROLE_PATH.admin, ROLE_PATH.user],
  //
  //   under: "text-msg",
  //   path:  text-msg/:id",
  //   component: TextMsgView,
  // },
  // ------------- Document Routes ------------------------
  // {
  //   name: "Documents",
  //   layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
  //   path: "/documents",
  //   icon: (
  //     <Icon as={AiFillFolderOpen} width="20px" height="20px" color="inherit" />
  //   ),
  //   component: Document,
  // },

  {
    name: "DocSign",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    path: "/docsign",
    icon: (
      <Icon as={MdLeaderboard} width="20px" height="20px" color="inherit" />
    ),
    component: DocSign,
  },
  // ----------------- Reporting Layout -----------------
  {
    name: "Reporting and Analytics",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    path: "/reporting-analytics",
    icon: (
      <Icon
        as={MdInsertChartOutlined}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    under: "Rentals",
    component: Report,
  },
  // ------------- user Routes ------------------------
  {
    name: "Users",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    path: "/user",
    under: "user",
    icon: <Icon as={HiUsers} width="20px" height="20px" color="inherit" />,
    component: User,
  },
  {
    name: "User View",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    parentName: "Email",
    under: "user",
    path: "/userView/:id",
    component: UserView,
  },
  // ========================== User layout ==========================

  // ========================== auth layout ==========================
  {
    name: "Sign In",
    layout: "/auth",
    path: "/sign-in",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: SignInCentered,
  },
];

export default routes;
